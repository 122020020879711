import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../../components/breadcrumb'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import Paragraph from '../../components/paragraph'
import SEO from '../../components/seo'


export const query = graphql`
  query ContactThanksPageQuery {
    ...SiteInfo
  }
`

export default ({ data }) => {
  const { links } = data.site.siteMetadata

  const page = 'Thanks'
  const title = `${page} - Contact`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: 'Contact',
      slug: links.contact.home,
    },
    {
      name: page,
      slug: links.contact.thanks,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description: title,
    keywords,
    name: title,
    slug: links.contact.thanks,
  }

  return (
    <Layout>
      <SEO keywords={keywords} schema={schema} title={title} />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      <Paragraph>
        Thank you for contacting us, we will respond as quickly as possible.
      </Paragraph>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
